
import {defineComponent, ref} from 'vue';
import {debounce} from 'lodash';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {IToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {usePagination} from '@/core/hooks/use-pagination';
import {swalNotification} from '@/core/helpers/utils';

import ToaXeService from '@/core/services/ToaXe.service';
import DanhSachToaXeTable from '@/views/crafted/pages/toa-xe/danh-sach-toa-xe-table/DanhSachToaXeTable.vue';
import Pagination from '@/components/shared/pagination/Pagination.vue';
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

export default defineComponent({
	name: 'danh-sach-toa-xe',

	components: {PageRowLayout, DanhSachToaXeTable, PerPageSelection, Pagination, ButtonCustom },

	setup() {
		const { push, goBack } = useRouterCustom();
		const { currentPage, totalItems, perPage, perPageArr, loading, searchTerm } = usePagination();
		const { ButtonsType } = useButtonCustom();
		const toaXeItems = ref<IToaXeResponseBase[]>([]);

		return {
			push,
			goBack,
			toaXeItems,
			currentPage, totalItems, perPage, perPageArr, loading, searchTerm,
			ButtonsType,
		}
	},

	async mounted() {
		setCurrentPageTitle('Toa xe');
		await this.fetchData();
	},

	watch: {
		async currentPage() {
			await this.fetchData();
		},

		async perPage() {
			await this.fetchData();
		}
	},

	methods: {
		async fetchData() {
			try {
				this.loading = true;
				const { data: { data: { data: res, total } } } = await ToaXeService.list(
					this.currentPage,
					this.perPage,
					0,
					this.searchTerm,
				);
				this.toaXeItems = res;
				this.totalItems = total;
				this.loading = false;
			} catch {
				this.loading = false;
				await swalNotification(
					'Có lỗi xảy ra khi lấy danh sách toa xe',
					'error',
				)
			}
		},

		search: debounce(function() {
      this.fetchData();
    }, 500),
	}
})
